
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexlpsQw17CGPMeta } from "/vercel/path0/pages/c/index.vue?macro=true";
import { default as indexYL7CS5qp2mMeta } from "/vercel/path0/pages/c/properties/[id]/index.vue?macro=true";
import { default as picturespHEg2ymFwhMeta } from "/vercel/path0/pages/c/properties/[id]/pictures.vue?macro=true";
import { default as workers6lZsfZ8JIvMeta } from "/vercel/path0/pages/c/properties/[id]/workers.vue?macro=true";
import { default as _91id_937nwfQJ6NMkMeta } from "/vercel/path0/pages/c/properties/[id].vue?macro=true";
import { default as indexx4TwB3ZKccMeta } from "/vercel/path0/pages/c/properties/index.vue?macro=true";
import { default as _91id_93hGFQJdBkHWMeta } from "/vercel/path0/pages/c/workers/[id].vue?macro=true";
import { default as indexeTtxXFK5wxMeta } from "/vercel/path0/pages/c/workers/index.vue?macro=true";
import { default as change_45passwordCl4xCMHGgGMeta } from "/vercel/path0/pages/change-password.vue?macro=true";
import { default as create_45accountnCD2YNpolvMeta } from "/vercel/path0/pages/create-account.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as propositions3bfHYmffwwMeta } from "/vercel/path0/pages/propositions.vue?macro=true";
import { default as reset_45password7wK2GPZgVKMeta } from "/vercel/path0/pages/reset-password.vue?macro=true";
import { default as utilssjXyT3kOWEMeta } from "/vercel/path0/pages/utils.vue?macro=true";
import { default as verifyYegocVK4w5Meta } from "/vercel/path0/pages/verify.vue?macro=true";
import { default as index3FpmPCw2x8Meta } from "/vercel/path0/pages/w/index.vue?macro=true";
import { default as _91id_93nhqcTcuR2XMeta } from "/vercel/path0/pages/w/missions/[id].vue?macro=true";
import { default as all6Uz25zHKgYMeta } from "/vercel/path0/pages/w/missions/all.vue?macro=true";
import { default as _91id_93ubZohOF4XfMeta } from "/vercel/path0/pages/w/proposals/[id].vue?macro=true";
export default [
  {
    name: "c",
    path: "/c",
    component: () => import("/vercel/path0/pages/c/index.vue")
  },
  {
    name: _91id_937nwfQJ6NMkMeta?.name,
    path: "/c/properties/:id()",
    component: () => import("/vercel/path0/pages/c/properties/[id].vue"),
    children: [
  {
    name: "c-properties-id",
    path: "",
    meta: indexYL7CS5qp2mMeta || {},
    component: () => import("/vercel/path0/pages/c/properties/[id]/index.vue")
  },
  {
    name: "c-properties-id-pictures",
    path: "pictures",
    meta: picturespHEg2ymFwhMeta || {},
    component: () => import("/vercel/path0/pages/c/properties/[id]/pictures.vue")
  },
  {
    name: "c-properties-id-workers",
    path: "workers",
    meta: workers6lZsfZ8JIvMeta || {},
    component: () => import("/vercel/path0/pages/c/properties/[id]/workers.vue")
  }
]
  },
  {
    name: "c-properties",
    path: "/c/properties",
    component: () => import("/vercel/path0/pages/c/properties/index.vue")
  },
  {
    name: "c-workers-id",
    path: "/c/workers/:id()",
    component: () => import("/vercel/path0/pages/c/workers/[id].vue")
  },
  {
    name: "c-workers",
    path: "/c/workers",
    component: () => import("/vercel/path0/pages/c/workers/index.vue")
  },
  {
    name: "change-password",
    path: "/change-password",
    component: () => import("/vercel/path0/pages/change-password.vue")
  },
  {
    name: "create-account",
    path: "/create-account",
    component: () => import("/vercel/path0/pages/create-account.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "propositions",
    path: "/propositions",
    component: () => import("/vercel/path0/pages/propositions.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/vercel/path0/pages/reset-password.vue")
  },
  {
    name: "utils",
    path: "/utils",
    component: () => import("/vercel/path0/pages/utils.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/vercel/path0/pages/verify.vue")
  },
  {
    name: "w",
    path: "/w",
    component: () => import("/vercel/path0/pages/w/index.vue")
  },
  {
    name: "w-missions-id",
    path: "/w/missions/:id()",
    component: () => import("/vercel/path0/pages/w/missions/[id].vue")
  },
  {
    name: "w-missions-all",
    path: "/w/missions/all",
    component: () => import("/vercel/path0/pages/w/missions/all.vue")
  },
  {
    name: "w-proposals-id",
    path: "/w/proposals/:id()",
    component: () => import("/vercel/path0/pages/w/proposals/[id].vue")
  }
]